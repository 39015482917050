import React from 'react'
import logo from '../../src/Images/white.logo.png';
import logopng from '../../src/Images/Logo.gif';
import { FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaTelegramPlane } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function Poster() {
    const socialLinks = [
        { icon: FaInstagram, link: 'https://www.instagram.com/wabbit.in/' },
        { icon: FaSquareXTwitter, link: 'https://x.com/Wabbit_India' },
        { icon: FaLinkedin, link: 'https://www.linkedin.com/company/wabbit-india/' },
        { icon: MdEmail, link: 'mailto:workwithwabbit@gmail.com', target: "_blank" }
    ];

    return (

        <div className='bg-black font-nunito overflow-y-scroll'>

            <nav className='mb-3 bg-black flex justify-center'>
                <img className='h-30 w-36' src={logo} alt="" />
            </nav>

            <div className='flex flex-wrap-reverse justify-evenly'>
                <div className='w-full md:w-1/3 p-4 mx-4 xmd:py-2 xmd:my-4  xmd:h-[300px]'>

                    <p className='text-4xl xs:text-[30px] text-white xs:text-2xl lg:text-[60px] xmd:text-[36px] lg:leading-snug'>
                        A Game-Changing Platform for Freelancers and Clients to Connect and Grow!
                    </p>
                </div>

                <div className='w-full md:w-1/3 p-2 flex justify-center relative top:10 '>
                    <img className='' src={logopng} alt="Logo" />
                </div>

            </div>

            <h1 className='text-2xl bg-white rounded-lg  m-3 md:hidden xs:block xs:text-center'>Stay tuned for our official launch
            </h1>

            <h1 className=' overflow-hidden lg:text-3xl my-4 mx-48 md:block xs:hidden md:text-xl text-white'>
                Stay tuned for our official launch and be among the first to experience the next
                generation for freelancing. Sign up now to get earrly access and be the first to know when we go live!
            </h1>

            <div
                className='flex justify-around flex-wrap  text-white lg:py-24'>

                <div
                    className='border-white w-56 text-center hover:text-black hover:bg-white hover:shadow-md hover:shadow-white rounded-lg my-3 border-2 px-8 cursor-pointer py-1 lg:py-3'>
                    <a href="https://forms.gle/RtG3k4FZGKkhTGfm6">
                        <button>
                            Join as Freelancer
                        </button>
                    </a>
                </div>

                <div className='border-white w-56 text-center hover:text-black hover:bg-white hover:shadow-md hover:shadow-white rounded-lg my-3 border-2 px-8 cursor-pointer py-1 lg:py-3'>

                    <a href="https://forms.gle/XWGQPruS7eox8RGaA">
                        <button>
                            Join Now
                        </button>
                    </a>
                </div>

                {/* <div className='border-white w-56 hover:text-black  rounded-lg my-3 border-2 px-2 cursor-pointer py-1 '>
                    <input type="text" placeholder='Enter your email address'
                        className='bg-black text-white outline-none '
                    />

                    <FaTelegramPlane className='inline text-2xl text-white' />
                </div> */}

            </div>

            <div className='lg:text-4xl text-white flex  gap-3 justify-center text-2xl py-5'>    {socialLinks.map(({ icon: Icon, link }, index) => (
                <a
                    href={link}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-0 sm:ml-4 transition-transform transform hover:scale-110"
                >
                    <Icon />
                </a>
            ))}
                <div className='h-full bg-black my-20'></div>

            </div>
        </div>
    )
}

export default Poster
